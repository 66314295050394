class GradientColor {
  setColorGradient: (colorStart: string, colorEnd: string) => void
  setMidpoint: (minNumber: number, maxNumber: number) => void
  getColor: (numberValue: number) => string|undefined

  constructor(startColor = "", endColor = "", minNum = 0, maxNum = 10) {
    this.setColorGradient = (colorStart, colorEnd) => {
      startColor = getHexColor(colorStart);
      endColor = getHexColor(colorEnd);
    };

    this.setMidpoint = (minNumber, maxNumber) => {
      minNum = minNumber;
      maxNum = maxNumber;
    };

    this.getColor = numberValue => {
      if (numberValue) {
        return (
          "#" +
          generateHex(
            numberValue,
            startColor.substring(0, 2),
            endColor.substring(0, 2)
          ) +
          generateHex(
            numberValue,
            startColor.substring(2, 4),
            endColor.substring(2, 4)
          ) +
          generateHex(
            numberValue,
            startColor.substring(4, 6),
            endColor.substring(4, 6)
          )
        );
      }
    };

    const generateHex = (_number: number, start: string, end: string) => {
      if (_number < minNum) {
        _number = minNum;
      } else if (_number > maxNum) {
        _number = maxNum;
      }

      const midPoint = maxNum - minNum;
      const startBase = parseInt(start, 16);
      const endBase = parseInt(end, 16);
      const average = (endBase - startBase) / midPoint;
      const finalBase = Math.round(average * (_number - minNum) + startBase);
      return finalBase < 16 ? "0" + finalBase.toString(16) : finalBase.toString(16);
    };

    const getHexColor = (color: string) => color.substring(color.length - 6, color.length);
  }
}

// tslint:disable-next-line:max-classes-per-file
class Gradient {
  setColorGradient: (...args: string[]) => Gradient
  getColors: () => string[]
  getColor: (val: number) => string|undefined
  setMidpoint: (val: number) => Gradient
  constructor(
    colorGradients: GradientColor[] = [],
    maxNum = 10,
    colors = ["", ""],
    intervals: Record<string, number>[] = []
  ) {
    const setColorGradient = (gradientColors: string[]) => {
      if (gradientColors.length < 2) {
        throw new Error(
          `setColorGradient should have more than ${gradientColors.length} color`
        );
      } else {
        const increment = maxNum / (gradientColors.length - 1);
        const firstColorGradient = new GradientColor();
        const lower = 0;
        const upper = increment;
        firstColorGradient.setColorGradient(
          gradientColors[0],
          gradientColors[1]
        );
        firstColorGradient.setMidpoint(lower, upper);
        colorGradients = [firstColorGradient];
        intervals = [
          {
            lower,
            upper,
          },
        ];

        for (let i = 1; i < gradientColors.length - 1; i++) {
          const gradientColor = new GradientColor();
          const _lower = increment * i;
          const _upper = increment * (i + 1);
          gradientColor.setColorGradient(
            gradientColors[i],
            gradientColors[i + 1]
          );
          gradientColor.setMidpoint(_lower, _upper);
          colorGradients[i] = gradientColor;
          intervals[i] = {
            lower: _lower,
            upper: _upper,
          };
        }
        colors = gradientColors;
      }
    };

    this.setColorGradient = (...gradientColors) => {
      setColorGradient(gradientColors);
      return this;
    };

    this.getColors = () => {
      const gradientColorsArray = [];
      for (let j = 0; j < intervals.length; j++) {
        const interval = intervals[j];
        const start = interval.lower === 0 ? 1 : Math.ceil(interval.lower);
        const end =
          interval.upper === maxNum
            ? interval.upper + 1
            : Math.ceil(interval.upper);
        for (let i = start; i < end; i++) {
          if (colorGradients[j].getColor(i)) {
            gradientColorsArray.push(colorGradients[j].getColor(i) as string);
          }
        }
      }
      return gradientColorsArray;
    };

    this.getColor = numberValue => {
      if (isNaN(numberValue)) {
        throw new TypeError(`getColor should be a number`);
      } else if (numberValue <= 0) {
        throw new TypeError(`getColor should be greater than ${numberValue}`);
      } else {
        const toInsert = numberValue + 1;
        const segment = (maxNum) / colorGradients?.length;
        const index = Math.min(
          Math.floor((Math.max(numberValue, 0)) / segment),
          colorGradients?.length - 1
        );
        return colorGradients[index].getColor(toInsert);
      }
    };

    this.setMidpoint = maxNumber => {
      if (!isNaN(maxNumber) && maxNumber >= 0) {
        maxNum = maxNumber;
        setColorGradient(colors);
      } else if (maxNumber <= 0) {
        throw new RangeError(`midPoint should be greater than ${maxNumber}`);
      } else {
        throw new RangeError("midPoint should be a number");
      }
      return this;
    };
  }
}

export default Gradient;