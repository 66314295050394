import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import LoadingPage from "components/MainRouter/components/LoadingPage";

import useChainedLoading from "hooks/useChainedLoading";

import { getUserInfoSelector } from "store/user/selectors";

const LoadingLayout: FC = ({ children }) => {
  const { ready } = useTranslation();
  const { fetching: userInfoFetching } = useSelector(getUserInfoSelector);
  
  const loading = useChainedLoading([!ready, userInfoFetching]);
  
  return (<>
    {!loading ? <>{children}</> : <LoadingPage/>}
  </>);
};

export default LoadingLayout;