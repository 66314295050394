import { FC, SVGProps } from "react";

{/* eslint-disable max-len */}
const KeyIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M9.49937 1.75C6.87594 1.75 4.74937 3.87656 4.74937 6.5C4.74937 7.59844 5.12281 8.60938 5.74781 9.41406L5.10562 10.0562L4.13219 9.08281C4.10839 9.05953 4.07642 9.04649 4.04312 9.04649C4.00983 9.04649 3.97786 9.05953 3.95406 9.08281L3.33219 9.70469C3.3089 9.72848 3.29586 9.76046 3.29586 9.79375C3.29586 9.82704 3.3089 9.85902 3.33219 9.88281L4.30562 10.8562L3.60406 11.5578L2.63062 10.5844C2.60683 10.5611 2.57486 10.548 2.54156 10.548C2.50827 10.548 2.4763 10.5611 2.4525 10.5844L1.83062 11.2063C1.80734 11.23 1.7943 11.262 1.7943 11.2953C1.7943 11.3286 1.80734 11.3606 1.83062 11.3844L2.80406 12.3578L1.78375 13.3781C1.76049 13.4016 1.74744 13.4333 1.74744 13.4664C1.74744 13.4995 1.76049 13.5312 1.78375 13.5547L2.44469 14.2156C2.49312 14.2641 2.57281 14.2641 2.62125 14.2156L6.58375 10.2531C7.41788 10.9001 8.44374 11.2509 9.49937 11.25C12.1228 11.25 14.2494 9.12344 14.2494 6.5C14.2494 3.87656 12.1228 1.75 9.49937 1.75ZM12.0181 9.01875C11.3462 9.69219 10.4509 10.0625 9.49937 10.0625C8.54781 10.0625 7.6525 9.69219 6.98062 9.01875C6.30719 8.34688 5.93687 7.45156 5.93687 6.5C5.93687 5.54844 6.30719 4.65313 6.98062 3.98125C7.6525 3.30781 8.54781 2.9375 9.49937 2.9375C10.4509 2.9375 11.3462 3.30781 12.0181 3.98125C12.6916 4.65313 13.0619 5.54844 13.0619 6.5C13.0619 7.45156 12.6916 8.34688 12.0181 9.01875Z" fill="#232325"/>
  </svg>
);

export default KeyIcon;
