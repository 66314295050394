import { FC } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";

const GoogleOAuth: FC = ({ children }) => {
  if (!process.env.REACT_APP_GOOGLE_CLIENT_ID) {
    return <>{children}</>;
  }
  return <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>{children}</GoogleOAuthProvider>;
};

export default GoogleOAuth;