import { colors } from "config/constants";

{/* eslint-disable max-len */}
const EllipsisHorizontalIcon = (): JSX.Element => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 15.9686C4 16.2313 4.05173 16.4913 4.15224 16.734C4.25275 16.9766 4.40007 17.1971 4.58579 17.3828C4.7715 17.5686 4.99198 17.7159 5.23463 17.8164C5.47728 17.9169 5.73736 17.9686 6 17.9686C6.26264 17.9686 6.52272 17.9169 6.76537 17.8164C7.00802 17.7159 7.2285 17.5686 7.41421 17.3828C7.59993 17.1971 7.74725 16.9766 7.84776 16.734C7.94827 16.4913 8 16.2313 8 15.9686C8 15.706 7.94827 15.4459 7.84776 15.2033C7.74725 14.9606 7.59993 14.7401 7.41421 14.5544C7.2285 14.3687 7.00802 14.2214 6.76537 14.1209C6.52272 14.0204 6.26264 13.9686 6 13.9686C5.73736 13.9686 5.47728 14.0204 5.23463 14.1209C4.99198 14.2214 4.7715 14.3687 4.58579 14.5544C4.40007 14.7401 4.25275 14.9606 4.15224 15.2033C4.05173 15.4459 4 15.706 4 15.9686ZM14 15.9686C14 16.4991 14.2107 17.0078 14.5858 17.3828C14.9609 17.7579 15.4696 17.9686 16 17.9686C16.5304 17.9686 17.0391 17.7579 17.4142 17.3828C17.7893 17.0078 18 16.4991 18 15.9686C18 15.4382 17.7893 14.9295 17.4142 14.5544C17.0391 14.1793 16.5304 13.9686 16 13.9686C15.4696 13.9686 14.9609 14.1793 14.5858 14.5544C14.2107 14.9295 14 15.4382 14 15.9686ZM24 15.9686C24 16.4991 24.2107 17.0078 24.5858 17.3828C24.9609 17.7579 25.4696 17.9686 26 17.9686C26.5304 17.9686 27.0391 17.7579 27.4142 17.3828C27.7893 17.0078 28 16.4991 28 15.9686C28 15.4382 27.7893 14.9295 27.4142 14.5544C27.0391 14.1793 26.5304 13.9686 26 13.9686C25.4696 13.9686 24.9609 14.1793 24.5858 14.5544C24.2107 14.9295 24 15.4382 24 15.9686Z"
      fill={colors.main}
    />
  </svg>
);

export default EllipsisHorizontalIcon;
