import { RefObject, useEffect } from 'react';

type TOnClickOutside = (event: MouseEvent) => void
type useOnClickOutsideProps<T> = {
  onClickOutside: TOnClickOutside,
  anchors?: RefObject<T>[],
  ignore?: boolean
}

function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  {
    anchors,
    onClickOutside,
    ignore,
  }: useOnClickOutsideProps<T>
): void {

  const handleClickOutside = (event: MouseEvent) => {
    if (anchors && anchors?.length > 0) {
      for (const clickedRef of anchors) {
        if (!clickedRef?.current || clickedRef?.current?.contains(event.target as Node)) {
          return;
        }
      }
    }

    onClickOutside(event);
  };

  useEffect(() => {
    if (ignore) return;

    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside]);
}

export default useOnClickOutside;
