import { FC } from "react";
import cn from "classnames";

import BNSpinner from "components/BNSpinner";

const LoadingPage: FC<{ className?: string }> = ({ className }) => (
  <div className={cn("loading-page", className)}>
    <BNSpinner/>
  </div>
);

export default LoadingPage;
