import { FC } from "react";
import cn from "classnames";

import { TBNDropdownItemProps } from "components/BNDropdown/types";

const BNDropdownItem: FC<TBNDropdownItemProps> = ({ children, className, onClick }) => (
  <div
    className={cn("dropdown-item", className)}
    onClick={onClick}
  >
    {children}
  </div>
);

export default BNDropdownItem;
