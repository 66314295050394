import round from "lodash/round";

import { ECurrency } from "config/types";

import { ELanguages } from "../i18n/constants";
import i18n from "../i18n";

import { abbreviate } from "./abbreviate";
import { currencySymbol } from "./currency";

// Format value
/**
 * currencyFormat(12345678.9, 2, 3, '.', ',');  // "12.345.678,90"
 * currencyFormat(123456.789, 4, 4, ' ', ':');  // "12 3456:7890"
 * currencyFormat(12345678.9, 0, 3, '-');       // "12-345-679"
 *
 * @param {integer} value: value
 * @param {integer|undefined} [n=2] n: length of decimal
 * @param {integer|undefined} [x=3] x: length of whole part
 * @param {string|undefined} [s=" "] s: sections delimiter
 * @param {string|undefined} [c=","] c: decimal delimiter
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const currencyFormat = (value: number|null|undefined, n=2, x=3, s = " ", c = ",") => {
  const symbol = currencySymbol(ECurrency.usd);
  if (value === null || value === undefined) return "???";
  const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : symbol) + ')';
  // tslint:disable-next-line:no-bitwise
  const num = value.toFixed(Math.max(0, ~~n));

  return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), symbol + '&' + (s || ','));
};

const getMaxNumberOfSignificantDigits = (value: number) : number => {
  const rounded = round(value, 3);
  return rounded >= 1000 ? Math.ceil(rounded).toString().length : 4;
};

// Format amount with digits
export const formatAmount = (
  value: number, digits: number|undefined = 2
) : string => Number(value).toLocaleString(i18n.language, {
  minimumSignificantDigits: digits,
  maximumSignificantDigits: getMaxNumberOfSignificantDigits(value)
});


// Format RUB with 2 digits
export const formatRubs = (value: number | null | undefined) : string => {
  const symbol = currencySymbol(ECurrency.rub);
  if (value === null || value === undefined) return "??? " + symbol;
  if (value === 0) return  "0 " + symbol;

  if (value > 9999999999 || (value < 0.0000001 && value > 0)) {
    return value.toExponential(4).replace(".", ",") + " "  + symbol;
  }

  return Number(value).toLocaleString(ELanguages.ruRU, {
    style: 'currency',
    currency: 'RUB',
    minimumSignificantDigits: 2,
    maximumSignificantDigits: getMaxNumberOfSignificantDigits(value)
  });
};


// Format USD with 2 digits
const formatDollars = (value: number | null | undefined) : string => {
  const symbol = currencySymbol(ECurrency.usd);
  if (value === null || value === undefined) return symbol + " ???";

  if (value > 9999999999 || (value < 0.0000001 && value > 0)) {
    return symbol + " " + value.toExponential(4).replace(".", ",");
  }

  return symbol + " " + Number(value).toLocaleString(ELanguages.ruRU, {
    minimumSignificantDigits: 2,
    maximumSignificantDigits: getMaxNumberOfSignificantDigits(value)
  });
};


// Format fiat amount with 2 digits
export const formatWithCurrencies = (
  value: number | null | undefined,
  currency: ECurrency,
  noResultIfEmpty?: boolean
): string => {
  if ((value === null || value === undefined) && noResultIfEmpty) {
    return "";
  }
  if (currency === ECurrency.usd) {
    return formatDollars(value);
  }
  return formatRubs(value);
};


const withCurrency = (value: string, currency: ECurrency, spaceBeforeCurrency: boolean|undefined = true): string => {
  const symbol = currencySymbol(currency);
  return spaceBeforeCurrency ? `${symbol} ${value}` : `${symbol}${value}`;
};


// Format fiat amount with abbreviation
export const abbreviateCurrency = (
  currency: ECurrency,
  value: number | null | undefined,
  spaceBeforeCurrency: boolean|undefined = true
): string => {
  if (value === null || value === undefined) return withCurrency("???", currency);
  if (value < 10000) {
    return withCurrency(currencyFormat(value, 0), currency, spaceBeforeCurrency);
  }
  return withCurrency(abbreviate(value), currency, spaceBeforeCurrency);
};


export const formatExchangeRate = (value: number | null | undefined) : string => {
  if (value === null || value === undefined) return "???";
  return Number(value).toLocaleString(ELanguages.ruRU, {
    minimumSignificantDigits: 4,
    maximumSignificantDigits: getMaxNumberOfSignificantDigits(value)
  });
};
