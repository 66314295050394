import { EEventType } from "../store/analytics/types";

export const sendGoogleEvent = (event: EEventType | string): void => {
  if (!process.env.REACT_APP_GOOGLE_ANALYTIC) return;

  const { gtag } = window;
  gtag('event', event);
};

export const setGoogleUserId = (userId: string | null): void => {
  if (!process.env.REACT_APP_GOOGLE_ANALYTIC) return;

  const { gtag } = window;

  gtag('config', process.env.REACT_APP_GOOGLE_ANALYTIC, {
    'user_id': userId || undefined
  });
};
