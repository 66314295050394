import { FC } from "react";

import { IIconsProps } from "config/types";

{/* eslint-disable max-len */}
const ClarityArchiveLineIcon: FC<IIconsProps> = ({ className, onClick }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick}>
    <g clipPath="url(#clip0_9171_93238)">
      <path d="M20.4615 21.6667H3.53846V15H2V21.6667C2 22.0203 2.16209 22.3594 2.4506 22.6095C2.73912 22.8595 3.13044 23 3.53846 23H20.4615C20.8696 23 21.2609 22.8595 21.5494 22.6095C21.8379 22.3594 22 22.0203 22 21.6667V15H20.4615V21.6667Z" fill="#232325"/>
      <path d="M9 16.5C9 16.6326 9.07902 16.7598 9.21967 16.8536C9.36032 16.9473 9.55109 17 9.75 17H14.25C14.4489 17 14.6397 16.9473 14.7803 16.8536C14.921 16.7598 15 16.6326 15 16.5C15 16.3674 14.921 16.2402 14.7803 16.1464C14.6397 16.0527 14.4489 16 14.25 16H9.75C9.55109 16 9.36032 16.0527 9.21967 16.1464C9.07902 16.2402 9 16.3674 9 16.5Z" fill="#232325"/>
      <path d="M8.5 12.25H2.5V8.75H5.5C5.1754 8.28815 4.9955 7.71097 4.99 7.11375C4.99 7.11375 4.99 7.035 4.99 7H2.125C1.82663 7 1.54048 7.13828 1.3295 7.38442C1.11853 7.63056 1 7.9644 1 8.3125V14H10L8.5 12.25Z" fill="#232325"/>
      <path d="M21.875 7H18.995V7.11375C18.9938 7.70886 18.8192 8.28581 18.5 8.75H21.5V12.25H15.5L14 14H23V8.3125C23 7.9644 22.8815 7.63056 22.6705 7.38442C22.4595 7.13828 22.1734 7 21.875 7Z" fill="#232325"/>
      <path d="M11.9684 13L16.6955 8.19499C16.7813 8.13116 16.8526 8.04925 16.9047 7.95483C16.9567 7.8604 16.9883 7.75566 16.9973 7.64768C17.0063 7.5397 16.9925 7.43101 16.9568 7.32896C16.9211 7.22691 16.8644 7.13388 16.7904 7.05617C16.7165 6.97846 16.627 6.91787 16.5282 6.87851C16.4293 6.83915 16.3233 6.82194 16.2174 6.82804C16.1114 6.83414 16.008 6.86341 15.9141 6.91386C15.8202 6.96431 15.738 7.03478 15.6731 7.12049L12.7093 10.1094V0.756694C12.7093 0.556006 12.6313 0.363538 12.4923 0.22163C12.3534 0.0797228 12.1649 0 11.9684 0C11.7719 0 11.5834 0.0797228 11.4445 0.22163C11.3055 0.363538 11.2274 0.556006 11.2274 0.756694V10.1397L8.26371 7.15076C8.12419 7.00927 7.93535 6.93019 7.73873 6.93089C7.54211 6.9316 7.35383 7.01205 7.21529 7.15454C7.07675 7.29703 6.99931 7.48989 7 7.69068C7.0007 7.89148 7.07947 8.08378 7.21899 8.22526L11.9684 13Z" fill="#232325"/>
    </g>
    <defs>
      <clipPath id="clip0_9171_93238">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>

);

export default ClarityArchiveLineIcon;
