import { all, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { sendAmplitudeEvent } from "utils/amplitudeAnalytic";
import { sendGoogleEvent } from "utils/googleAnalytic";
import { sendYandexEvent } from "utils/ymAnalytic";

import { EEventType, ISendGAStatisticPayload } from "./types";


function* analyticEventAction(action: PayloadAction<ISendGAStatisticPayload>): Generator {
  const { event } = action.payload;

  sendGoogleEvent(event);
  sendAmplitudeEvent(event);
  sendYandexEvent(event);
}

function* Saga(): Generator {
  yield all([
    takeLatest(ANALYTIC_EVENT, analyticEventAction),
  ]);
}

export const ANALYTIC_EVENT = 'ANALYTIC_EVENT';
export const analyticEvent = (
  event: EEventType | string,
): PayloadAction<ISendGAStatisticPayload> => ({
  type: ANALYTIC_EVENT,
  payload: { event }
});
export default Saga;
