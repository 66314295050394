import { FC } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Dropdown } from "antd";

import { fastChecksDetailsSelector } from "store/fastChecks/selectors";

import EllipsisVerticalIcon from "../../../../../../Icons/EllipsisVerticalIcon";

import { dropdownAlign } from "../../index";

const MobileHeaderFastCheck: FC = () => {
  const { t } = useTranslation();
  const { data: fastCheckDetails, initialLoading, fetching } = useSelector(fastChecksDetailsSelector);
  const reportUrl = fastCheckDetails?.report_url;
  const reportUrlWithoutRisks = fastCheckDetails?.report_without_risks_url;
  const items = [];
  if (initialLoading || fetching) return null;

  const getItem = (url: string | null, key: string) => ({
    key,
    label:!!url ? (
      <a href={url}
        rel="noreferrer" target="_blank" className='download-pdf'>
        <p>{t(key)}</p>
      </a>
    ): (
      t(key)
    ),
  });

  if (reportUrl) {
    items.push(getItem(reportUrl, 'transfersDetails.downloadPdf'));
  }

  if (reportUrlWithoutRisks) {
    items.push(getItem(reportUrlWithoutRisks, 'transfersDetails.downloadPdfWithoutRisks'));
  }

  return <Dropdown
    menu={{
      className: "bn-dropdown__menu",
      items: [...items],
    }}
    trigger={["click"]}
    placement="bottomRight"
    align={dropdownAlign}
  >
    <a
      onClick={e => e.preventDefault()}
      className="header-mobile__menu"
    >
      <EllipsisVerticalIcon/>
    </a>
  </Dropdown>;
};

export default MobileHeaderFastCheck;
