import { FC } from "react";
import { Dropdown } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ItemType } from "antd/es/menu/hooks/useItems";

import EllipsisVerticalIcon from "components/Icons/EllipsisVerticalIcon";

import { EModals, ModalNewTransferData } from "store/modals/types";
import { transfersDetailsSelector } from "store/transfers/selectors";
import { ETransferType } from "store/transfers/types";
import { openModal } from "store/modals/actions";

import { dropdownAlign } from "../..";

const MobileHeaderTransfersMenu: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data: transferDetails, initialLoading, fetching } = useSelector(transfersDetailsSelector);
  const disabled = transferDetails?.type === ETransferType.withdrawal
    || transferDetails?.type === ETransferType.deposit;

  const reportUrl = transferDetails?.report_url || '';
  const reportUrlWithoutRisks = transferDetails?.report_without_risks_url || '';

  if ( initialLoading || fetching) return null;

  const getItem = (url: string | null, key: string) => ({
    key,
    label:!!url ? (
      <a href={url} rel="noreferrer" target="_blank" className='download-pdf'>
        <p>{t(key)}</p>
      </a>
    ): (
      t(key) // Directly render the translated text if no URL
    ),
    disabled: !url,
  });

  const items = [
    getItem(reportUrl, 'transfersDetails.downloadPdf'),
    getItem(reportUrlWithoutRisks, 'transfersDetails.downloadPdfWithoutRisks'),
    !disabled && {
      key: "report_url_3",
      onClick: () => {
        if (!transferDetails?.type) return;

        dispatch(openModal<ModalNewTransferData>({
          modal: EModals.transferFast,
          data: {
            transferType: transferDetails?.type
          }
        }));
      },
      label: t("transfersDetails.bindTransaction")
    },
  ].filter(Boolean) as ItemType[];

  return <Dropdown
    menu={{
      className: "bn-dropdown__menu",
      items: [...items],
    }}
    trigger={["click"]}
    placement="bottomRight"
    align={dropdownAlign}
  >
    <a
      onClick={e => e.preventDefault()}
      className="header-mobile__menu"
    >
      <EllipsisVerticalIcon/>
    </a>
  </Dropdown>;
};

export default MobileHeaderTransfersMenu;
