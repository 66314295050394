import { colors } from "config/constants";

{/* eslint-disable max-len */}
const ArrowSmallIcon = (): JSX.Element => (
  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.1315 0.6875H0.868974C0.561161 0.6875 0.389286 1.0125 0.579911 1.23437L5.71116 7.18437C5.85804 7.35469 6.14085 7.35469 6.28929 7.18437L11.4205 1.23437C11.6112 1.0125 11.4393 0.6875 11.1315 0.6875Z"
      fill={colors.gray9}
    />
  </svg>
);

export default ArrowSmallIcon;
