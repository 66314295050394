import { FC } from "react";
import cn from "classnames";

import { TCryptoProps } from "../../types";

type TIconNotListedInCatalogProps = Pick<TCryptoProps, "asset" | "style" | "className">

const IconNotListedInCatalog: FC<TIconNotListedInCatalogProps> = ({ asset, style, className }) => {
  const name = asset?.replaceAll(" ", "").slice(0, 4);

  const formClass = () => {
    if (name?.length === 4) {
      return "lng-4";
    } else if (name?.length === 3) {
      return "lng-3";
    }
  };

  return (
    <div className={cn("crypto-icon not-listed-in-catalog", className, formClass())} style={style}>
      {name}
    </div>
  );
};

export default IconNotListedInCatalog;
