import { createContext, FC, useContext } from "react";
import cn from "classnames";
import { Link, useLocation } from "react-router-dom";
import isFunction from "lodash/isFunction";

import { TTransfer } from "store/transfers/types";

import { getTransfersDetailPath } from "../../config/paths";

import TransfersDirection from "./components/TransfersDirection";
import TransferCounterparty from "./components/TransferCounterparty";
import TransferCrypto from "./components/TransferCrypto";
import TransferAdditional from "./components/TransferAdditional";

export const TransferContext = createContext<Partial<TTransfer>>({});
export const useTransferContext = (): Partial<TTransfer> => useContext(TransferContext);

export type TClientsTransactionProps = TTransfer & {
  isContinuation: boolean;
  hasContinuation: boolean;
  onClick?: () => void
  redirectPath?: string
}

const Transfer: FC<TClientsTransactionProps> = ({
  isContinuation,
  hasContinuation,
  onClick,
  redirectPath,
  ...rest
}) => {
  const location = useLocation();

  const handleClick = () => {
    if (isFunction(onClick)) onClick();
  };

  return (
    <TransferContext.Provider value={{ ...rest }}>
      <Link
        to={{
          pathname: redirectPath || getTransfersDetailPath(rest.id),
          state: { routeModal: location }
        }}
        onClick={handleClick}
        className={cn("transfer", {
          isContinuation,
          hasContinuation,
          [rest.direction]: rest.direction
        })}
        id={rest.id}
      >
        <TransfersDirection />
        <TransferCrypto />
        <TransferCounterparty />
        <TransferAdditional />
      </Link>
    </TransferContext.Provider>
  );
};

export default Transfer;
