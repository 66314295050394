import { FC, SVGProps } from "react";

import { colors } from "config/constants";

{/* eslint-disable max-len */}

const PlusIcon: FC<SVGProps<SVGSVGElement>> = ({ className, onClick }):JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick}>
    <path d="M4.00348 12.9931C3.73171 12.9931 3.49129 12.9 3.28223 12.7138C3.09408 12.5069 3 12.269 3 12C3 11.731 3.09408 11.5034 3.28223 11.3172C3.49129 11.1103 3.73171 11.0069 4.00348 11.0069H10.6829C10.8711 11.0069 10.9652 10.9241 10.9652 10.7586V4.02414C10.9652 3.75517 11.0697 3.51724 11.2787 3.31034C11.4878 3.10345 11.7282 3 12 3C12.2718 3 12.5122 3.10345 12.7213 3.31034C12.9303 3.51724 13.0348 3.75517 13.0348 4.02414V10.7586C13.0348 10.9241 13.1289 11.0069 13.3171 11.0069H19.9965C20.2683 11.0069 20.4983 11.1103 20.6864 11.3172C20.8955 11.5034 21 11.731 21 12C21 12.269 20.8955 12.5069 20.6864 12.7138C20.4983 12.9 20.2683 12.9931 19.9965 12.9931H13.3171C13.1289 12.9931 13.0348 13.0759 13.0348 13.2414V19.9759C13.0348 20.2448 12.9303 20.4828 12.7213 20.6897C12.5122 20.8966 12.2718 21 12 21C11.7282 21 11.4878 20.8966 11.2787 20.6897C11.0697 20.4828 10.9652 20.2448 10.9652 19.9759V13.2414C10.9652 13.0759 10.8711 12.9931 10.6829 12.9931H4.00348Z" fill={colors.main}/>
  </svg>
);

export default PlusIcon;

