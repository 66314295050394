import { FC, SVGProps } from "react";

{/* eslint-disable max-len */}

const PlusIcon: FC<SVGProps<SVGSVGElement>> = ({ className, onClick }):JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick}>
    <path d="M20.5461 5.61287C20.5344 5.59178 20.518 5.57068 20.4992 5.55428C20.382 5.43709 20.1922 5.43709 20.075 5.55428L16.0367 9.59256L14.4453 8.00115L18.4859 3.96053C18.5047 3.94178 18.5187 3.92303 18.5328 3.90193C18.6172 3.75896 18.5703 3.57615 18.4273 3.49178C16.1258 2.1324 13.1117 2.44412 11.1312 4.42225C9.56093 5.99256 9.04062 8.21912 9.57265 10.2207L2.70546 17.0879C2.63515 17.1582 2.63984 17.273 2.71249 17.3457L6.68984 21.323C6.76249 21.3957 6.87968 21.4004 6.94765 21.3301L13.8125 14.4652C15.8164 14.9996 18.043 14.4816 19.6156 12.909C21.5937 10.9285 21.9055 7.91443 20.5461 5.61287ZM18.4226 11.7137C17.0609 13.0754 15.0172 13.3379 13.393 12.5012L13.1867 12.7074L13.1844 12.7051L6.76249 19.1293L4.90624 17.273L10.2992 11.8801C10.2992 11.8801 10.2992 11.8824 10.3016 11.8824L11.5391 10.6449C10.7023 9.02068 10.9648 6.97693 12.3266 5.61521C12.7657 5.17564 13.2946 4.83605 13.8771 4.61964C14.4596 4.40324 15.0819 4.31512 15.7016 4.36131L12.5867 7.47381C12.446 7.61466 12.367 7.80559 12.367 8.00467C12.367 8.20374 12.446 8.39468 12.5867 8.53553L15.5047 11.4535C15.6455 11.5942 15.8365 11.6732 16.0355 11.6732C16.2346 11.6732 16.4256 11.5942 16.5664 11.4535L19.6789 8.341C19.7656 9.55506 19.3414 10.7972 18.4226 11.7137Z" fill="#232325"/>
  </svg>
);

export default PlusIcon;

