import { createSlice } from "@reduxjs/toolkit";

import { defaultState } from "../constants";
import { IPayloadAction } from "../rootInterface";

import { ApiKeysCreateOptions, ApiKeysDeleteOptions, ApiKeysEditOptions, TApiKeysStoreState } from "./types";

const initialState: TApiKeysStoreState = {
  apiKeysAllState: defaultState,
  apiKeysCreateState: defaultState,
  apiKeysEditState: defaultState,
  apiKeysDeleteState: defaultState,
};

const apiKeysSlice = createSlice({
  name: "apiKeys",
  initialState,
  reducers: {
    apiKeysAllRequest(state) {
      state.apiKeysAllState = {
        ...state.apiKeysAllState,
        fetching: true,
      };
    },
    apiKeysAllSuccess(state, action) {
      state.apiKeysAllState = {
        ...state.apiKeysAllState,
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    apiKeysAllFailure(state, action) {
      state.apiKeysAllState = {
        ...state.apiKeysAllState,
        fetching: false,
        failure: action.payload,
      };
    },
    apiKeysAllRemove(state, action) {
      state.apiKeysAllState.data = state.apiKeysAllState.data?.filter(item => item.id !== action.payload.id) || [];
    },
    apiKeysAllAppend(state, action) {
      state.apiKeysAllState.data?.push(action.payload);
    },
    apiKeysAllEdit(state, action) {
      state.apiKeysAllState.data = state.apiKeysAllState.data?.map(item => {
        if (item.id !== action.payload.id) return item;
        return action.payload;
      }) || [];
    },

    apiKeysCreateRequest(state, _: IPayloadAction<ApiKeysCreateOptions>) {
      state.apiKeysCreateState = {
        ...state.apiKeysCreateState,
        fetching: true,
      };
    },
    apiKeysCreateSuccess(state, action) {
      state.apiKeysCreateState = {
        ...state.apiKeysCreateState,
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    apiKeysCreateFailure(state, action) {
      state.apiKeysCreateState = {
        ...state.apiKeysCreateState,
        fetching: false,
        failure: action.payload,
      };
    },
    apiKeysCreateClear(state) {
      state.apiKeysCreateState = { ...defaultState };
    },

    apiKeysEditRequest(state, _: IPayloadAction<ApiKeysEditOptions>) {
      state.apiKeysEditState = {
        ...state.apiKeysEditState,
        fetching: true,
      };
    },
    apiKeysEditSuccess(state, action) {
      state.apiKeysEditState = {
        ...state.apiKeysEditState,
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    apiKeysEditFailure(state, action) {
      state.apiKeysEditState = {
        ...state.apiKeysEditState,
        fetching: false,
        failure: action.payload,
      };
    },

    apiKeysDeleteRequest(state, _: IPayloadAction<ApiKeysDeleteOptions>) {
      state.apiKeysDeleteState = {
        ...state.apiKeysDeleteState,
        fetching: true,
      };
    },
    apiKeysDeleteSuccess(state, action) {
      state.apiKeysDeleteState = {
        ...state.apiKeysDeleteState,
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    apiKeysDeleteFailure(state, action) {
      state.apiKeysDeleteState = {
        ...state.apiKeysDeleteState,
        fetching: false,
        failure: action.payload,
      };
    },
  },
});

export const {
  apiKeysAllRequest,
  apiKeysAllSuccess,
  apiKeysAllFailure,
  apiKeysAllRemove,
  apiKeysAllAppend,
  apiKeysAllEdit,

  apiKeysCreateRequest,
  apiKeysCreateSuccess,
  apiKeysCreateFailure,
  apiKeysCreateClear,

  apiKeysEditRequest,
  apiKeysEditSuccess,
  apiKeysEditFailure,

  apiKeysDeleteRequest,
  apiKeysDeleteSuccess,
  apiKeysDeleteFailure,
} = apiKeysSlice.actions;

export default apiKeysSlice.reducer;
