import { FC, SVGProps } from "react";

{/* eslint-disable max-len */}

const UserIcon: FC<SVGProps<SVGSVGElement>> = ({ className, onClick }):JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick}>
    <path d="M20.1202 17.8969C19.6781 16.8496 19.0365 15.8984 18.2311 15.0961C17.4282 14.2915 16.4771 13.65 15.4303 13.207C15.421 13.2023 15.4116 13.2 15.4022 13.1953C16.8624 12.1406 17.8116 10.4227 17.8116 8.48438C17.8116 5.27344 15.21 2.67188 11.9991 2.67188C8.78815 2.67188 6.18658 5.27344 6.18658 8.48438C6.18658 10.4227 7.1358 12.1406 8.59596 13.1977C8.58658 13.2023 8.57721 13.2047 8.56783 13.2094C7.51783 13.6523 6.57564 14.2875 5.76705 15.0984C4.96246 15.9013 4.32097 16.8524 3.87799 17.8992C3.44281 18.924 3.2081 20.0228 3.18658 21.1359C3.18596 21.161 3.19034 21.1858 3.19949 21.2091C3.20863 21.2324 3.22234 21.2537 3.23981 21.2716C3.25729 21.2895 3.27817 21.3037 3.30123 21.3134C3.32429 21.3231 3.34906 21.3281 3.37408 21.3281H4.78033C4.88346 21.3281 4.96549 21.2461 4.96783 21.1453C5.01471 19.3359 5.74127 17.6414 7.02564 16.357C8.35455 15.0281 10.1194 14.2969 11.9991 14.2969C13.8788 14.2969 15.6436 15.0281 16.9725 16.357C18.2569 17.6414 18.9835 19.3359 19.0303 21.1453C19.0327 21.2484 19.1147 21.3281 19.2178 21.3281H20.6241C20.6491 21.3281 20.6739 21.3231 20.6969 21.3134C20.72 21.3037 20.7409 21.2895 20.7584 21.2716C20.7758 21.2537 20.7895 21.2324 20.7987 21.2091C20.8078 21.1858 20.8122 21.161 20.8116 21.1359C20.7881 20.0156 20.5561 18.9258 20.1202 17.8969ZM11.9991 12.5156C10.9233 12.5156 9.9108 12.0961 9.14908 11.3344C8.38736 10.5727 7.96783 9.56016 7.96783 8.48438C7.96783 7.40859 8.38736 6.39609 9.14908 5.63437C9.9108 4.87266 10.9233 4.45312 11.9991 4.45312C13.0749 4.45312 14.0874 4.87266 14.8491 5.63437C15.6108 6.39609 16.0303 7.40859 16.0303 8.48438C16.0303 9.56016 15.6108 10.5727 14.8491 11.3344C14.0874 12.0961 13.0749 12.5156 11.9991 12.5156Z" fill="#232325"/>
  </svg>
);

export default UserIcon;

