import { FC } from "react";
import { Layout as AntLayout } from "antd";
import cn from "classnames";


import SidebarBody from "./components/SidebarBody";

const { Sider: AntSider } = AntLayout;

const Sidebar: FC = () => (
  <AntSider className={cn("sidebar")} theme="light">
    <SidebarBody/>
  </AntSider>
);

export default Sidebar;
