import Gradient from "./index";

export enum ECategoryChartColors {
  noRisk = "#99F2FF",
  low = "#32D74B",
  medium = "#F6DA00",
  high = "#FF891D",
  sever = "#EC170B",
}

export enum TGradientKeys {
  noRisk = "noRisk",
  low = "low",
  medium = "medium",
  high = "high",
  sever = "sever",
}

type TGradientValue = {
  transitionalColor: ECategoryChartColors|ECategoryChartColors[],
  condition: (risk: number) => boolean,
  count: number,
  colors: (ECategoryChartColors|string)[]
}
class RiskGradient {
  numberOfSamples = 100;
  gradientGroups: Record<TGradientKeys, TGradientValue> = {
    [TGradientKeys.noRisk]: {
      transitionalColor: ECategoryChartColors.noRisk,
      condition: (risk: number) => risk === 0,
      count: 1,
      colors: [ECategoryChartColors.noRisk]
    },
    [TGradientKeys.low]: {
      transitionalColor: [ECategoryChartColors.low, ECategoryChartColors.medium],
      condition: (risk: number) => risk > 0 && risk <= 30,
      count: 29,
      colors: []
    },
    [TGradientKeys.medium]: {
      transitionalColor: [ECategoryChartColors.medium, ECategoryChartColors.high],
      condition: (risk: number) => risk > 30 && risk <= 70,
      count: 40,
      colors: []
    },
    [TGradientKeys.high]: {
      transitionalColor: [ECategoryChartColors.high, ECategoryChartColors.sever],
      condition: (risk: number) => risk > 70 && risk < 100,
      count: 29,
      colors: []
    },
    [TGradientKeys.sever]: {
      transitionalColor: ECategoryChartColors.sever,
      condition: (risk: number) => risk === 100,
      count: 1,
      colors: [ECategoryChartColors.sever]
    },
  }
  constructor() {
    this.generateGroupsColors();
  }
  generateGroupsColors() {
    for (const [key, gradient] of Object.entries(this.gradientGroups)) {
      if (gradient.count === 1) continue;

      this.gradientGroups[key as TGradientKeys].colors = new Gradient()
        .setColorGradient(...gradient.transitionalColor)
        .setMidpoint(gradient.count)
        .getColors();
    }
  }
  getGradient(): string[] {
    return [].concat(...Object.values(this.gradientGroups).map(group => group.colors) as []);
  }
}

export const riskGradients = new RiskGradient().getGradient();

export const getRiskGradientColor = (risk: number): string|undefined => {
  if (risk > 1 || risk < 0) return undefined;
  return riskGradients[risk === 0 ? 0 : Math.round(risk*100)-1];
};


