import { PayloadAction } from "@reduxjs/toolkit";

export const INITIAL_REQUESTS = "INITIAL_REQUESTS";

export const initialRequest = (): PayloadAction<unknown> => ({
  type: INITIAL_REQUESTS,
  payload: {}
});

export const USER_LOGOUT = "USER_LOGOUT";

export const userLogout = (): PayloadAction<unknown> => ({
  type: USER_LOGOUT,
  payload: {}
});
