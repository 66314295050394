export type RGB = {
  r: number
  g: number
  b: number
}

const hexToRgb = (hex: string): RGB|undefined => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex.toLowerCase());
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : undefined;
};

export default hexToRgb;
